#normal_login.login-form {
  max-width: 300px;
}
#normal_login.login-form-forgot {
  float: right;
}
#normal_login.ant-col-rtl.login-form-forgot {
  float: left;
}
#normal_login.login-form-button {
  width: 100%;
}
